
import './App.css';
import DashboardContainer from './containers/dashboard';
import { Navbar,Nav,NavbarBrand,NavDropdown } from "react-bootstrap";

function App() {
  return (

<DashboardContainer></DashboardContainer>
  );
}

export default App;
